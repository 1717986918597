import React from 'react';
import { Input } from '@chakra-ui/react'
import styled from '@emotion/styled';
import Box from './Box';
import Flex from './Flex';
import { responsive } from '../contexts/responsive';

const CustomRadio = styled(Box)`
  input:checked + label {
    color: white;
    background: black;
  }
  @media screen and (min-width: 992px) {
    label:hover {
      color: white;
      background: black;
    }
  }
`

const ReRadio = ({ options, name, handleChange }) => {
  return (
    <Flex mx={responsive(0, '-1em')} justifyContent={responsive('space-between', 'flex-start')} flexWrap={'wrap'}>
      {options.map((opt, i) => (
        <Box width={responsive('30.7%', '20%')}>
          <CustomRadio
            mt="0.875rem"
            key={opt + i}
            mx={responsive(0, '1em')}
          >
            <Input
              type="radio"
              name={name}
              id={opt}
              value={opt}
              onChange={handleChange}
              position={'absolute'}
              width={0}
              height={0}
              opacity={0}
            />
            <Box
              for={opt}
              as="label"
              py={responsive('0.25rem', '0.5rem')}
              border={responsive('1.5px solid', '3px solid')}
              borderColor="black"
              fontWeight={'500'}
              borderRadius={'1.375rem'}
              textAlign={'center'}
              display={'block'}
              fontSize={responsive('0.875em', '1.875em')}
              cursor="pointer"
            >{opt}</Box>
          </CustomRadio>
        </Box>
      ))}
    </Flex>
  )
}

export default ReRadio;
