import React from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react'
import get from 'lodash/get';

import Flex from './Flex';
import Box from './Box';
import { responsive } from '../contexts/responsive';

const sliderValues = [12.5, 37.5, 62.5, 87.5]
const height = responsive('12px', '28px')

const ReSlider = ({ marks, handleChange, name, silderProps, values, ...props }) => (
  <Flex alignItems={'center'} {...props}>
    <Box width="12.5%" height={height} bg="black" />
    <Slider
      flex={1}
      min={12.5}
      max={87.5}
      step={12.5}
      defaultValue={50}
      name={name}
      onChange={(e) => {
        const values = { target: { value: e, name } }
        handleChange(values)
      }}
      {...silderProps}
    >
      {marks.map((mark, k) => (
        <SliderMark
          key={mark + k}
          bottom="110%"
          textAlign={'center'}
          width={`${mark.length}em`}
          value={sliderValues[k]}
          fontSize={responsive('0.8125em', '2.25em')}
          transform={responsive('translateX(-50%)', 'translate(-50%, -50%)')}
        >
          {mark}
        </SliderMark>
      ))}
      <SliderTrack borderRadius="0" height={height} bg="custom.sliderGray">
        <SliderFilledTrack bg="black" />
      </SliderTrack>
      <SliderThumb border="2px solid black" borderColor="black" width={responsive('1.5em', '3.875em')} height={responsive('1.5em', '3.875em')} />
    </Slider>
    <Box width="12.5%" height={height} bg={get(values, [name]) === 87.5 ? 'black' : 'custom.sliderGray'} />
  </Flex>
)

export default ReSlider;
