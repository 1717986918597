import { Input } from '@chakra-ui/react';
import React from 'react';
import { responsive } from '../contexts/responsive';

const ReInput = (props) => (
  <Input
    borderRadius={'1.5rem'}
    border={responsive('1.5px solid', '3px solid')}
    borderColor="black"
    fontSize={responsive('0.875em', '2.25em')}
    py={responsive('0.25rem', '0.75rem')}
    height="auto"
    {...props}
  />
)

export default ReInput;
