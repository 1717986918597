import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { Textarea } from '@chakra-ui/react';
import styled from '@emotion/styled';
import * as yup from 'yup';
import { IoMdSend } from "react-icons/io";
import { navigate } from 'gatsby';
import { useLocalStorage } from 'react-use';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import Image from '../../components/Image';
import Input from '../../components/Input';
import Slider from '../../components/Slider';
import Button from '../../components/Button';
import Radio from '../../components/Radio';
import Checkbox from '../../components/Checkbox';
import BackgroundImage from '../../components/BackgroundImage';
import { Media, responsive } from '../../contexts/responsive';
import sanitize from '../../utils/sanitize';

import need from './need.svg'
import contact from './contact.svg'
import contact_desk from './contact-desk.svg'
import normal_info from './normal_info.svg'

const CustomCheckBox = styled(Checkbox)`
  justify-content: center;
  .chakra-checkbox__control {
    display: none;
  }
  .chakra-checkbox__label {
    margin-left: 0;
  }
  .chakra-checkbox__label {
    font-size: 1.875em;
  }
  @media screen and (max-width: 992px) {
    .chakra-checkbox__label {
      font-size: 0.875em;
    }
  }
`

const needs = [
  {
    name: 'purpose',
    title: '溝通目的',
    buttons: [
      '呈現數據報告', '教學方法指南', '強調產品優勢', '引導思考改變', '說明事情脈絡', '說服參與行動'
    ],
    width: responsive('48%', '25%')
  },
  {
    name: 'method',
    title: '製作物呈現形式',
    width: responsive('100%', '49%'),
    buttons: [
      {
        subtitle: '基本資訊設計',
        subName: 'design',
        options: ['溝通策略設定', '資訊圖表/懶人包', '說明動畫', '手冊/成果報告', '互動式網頁/測驗', '簡報分鏡與製作', '展場中的資訊圖表']
      },
      {
        subtitle: '進化溝通經營',
        subName: 'communication',
        options: ['量化分眾分析', '搜尋引擎優化（SEO）', '資訊設計工作坊']
      }
    ],
  },
]

const scales = [
  {
    title: '完成時間',
    name: 'duration',
    marks: ['1個月', '1.5個月', '2個月', '2個月以上'],
    number: [1, 1.5, 2],
    unit: '個月',
  },
  {
    title: '預算範圍',
    name: 'budget',
    marks: ['50萬以下', '50-100萬', '100-300萬', '300萬以上'],
    number: [50, 100, 300],
    unit: '萬',
  },
]

const isScale = scales.reduce((obj, { name }, i) => {
  obj[name] = i
  return obj
}, {})

const getScaleValue = (index, value) => {
  const { marks, number, unit } = scales[index]
  const tick = (87.5 - 12.5) / (marks.length - 1)
  const realValue = value - 12.5
  const pos = Math.floor(realValue / tick)
  const res = realValue % tick
  if (number[pos] && number[pos + 1]) {
    return `${number[pos] + (number[pos + 1] - number[pos]) * (res / tick)}${unit}`
  }
  return marks[pos]
}

const info = [
  {
    label: '我的名字',
    placeholder: '李賴伯',
    name: 'name',
    require: true
  },
  {
    label: '我的信箱',
    placeholder: 'xxx@gmail.com',
    name: 'email',
    require: true
  },
  {
    label: '我的電話',
    name: 'phone',
    require: true
  },
  {
    label: '我的公司',
    name: 'company',
    require: true
  },
  {
    label: '我的職位',
    name: 'title',
    require: true
  },
  {
    label: '我的產業',
    name: 'industry',
    options: ['生活飲食', '環境自然', '社會人文', '財金經濟', '醫療保健', '資訊科技', '政治時事', '教育職訓', '藝文設計', '運動休閒', '行銷購物', '新聞媒體', '其他'],
    Comp: Radio,
    require: true
  },
  {
    label: '其他備註',
    placeholder: '歡迎附上專案需求描述，以及適合的參考連結與說明，加快聯繫的流程！',
    name: 'note',
    Comp: (props) => (
      <Textarea
        borderRadius={'1.5rem'}
        border={responsive('1.5px solid', '3px solid')}
        borderColor="black"
        fontSize={responsive('0.875em', '2.25em')}
        height={responsive('10.25rem', '6.5em')}
        {...props}
      />
    )
  },
]

let schema = yup.object().shape({
  name: yup.string().required('此欄位必填'),
  // phone: yup.number('格式錯誤').required('此欄位必填').positive('格式錯誤').integer('格式錯誤'),
  email: yup.string().email('格式錯誤').required('此欄位必填'),
  company: yup.string().required('此欄位必填'),
  title: yup.string().required('此欄位必填'),
  industry: yup.string().required('此欄位必填'),
});

const ContactPage = () => {
  const [, setValue] = useLocalStorage('email');
  const [validate, setSchema] = useState()
  // const toast = useToast()
  return (
    <Formik
      initialValues={{}}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting, error }) => {
        setSubmitting(true)
        setValue(sanitize(values.email))
        const sanitized = sanitize(values)
        const formData = Object.keys(sanitized).reduce((fd, key) => {
          let value = sanitized[key]
          if (typeof isScale[key] === 'number') {
            value = getScaleValue(isScale[key], value)
          } else if (isArray(value)) {
            value = value.reduce((merged, v) => {
              if (v) {
                merged = merged.concat(v)
              }
              return merged
            }, []).join('、')
          }
          fd.append(key, value)
          return fd
        }, new FormData())
        await fetch(process.env.GATSBY_CONTACT_FORM, {
          method: 'POST',
          body: formData,
        })
        if (window.gtag) {
          window.gtag("event", "generate_lead");
        }
        setSubmitting(false)
        navigate('/contact/success')
      }}
    >
      {({ handleChange, isSubmitting, errors }) => (
        <Form>
          <Box maxWidth={responsive('32.75em', '77.875em')} pt={responsive('2.375em', '3em')} pb={responsive('2.375em', '3em')} mx="auto">
            <Flex flexDirection={responsive('row', 'column-reverse')} pb={responsive('2.75em', '7.75em')} px={responsive('1.375em', '2em')}>
              <Box mt={responsive(0, '1.25em')} width={responsive('88%', '100%')} pr={responsive('8.5%', 0)} pl={responsive('0.75em', 0)}>
                <Text.Bold textAlign={responsive('left', 'center')} lineHeight="1" fontSize={responsive('1.75em', '3.75em')}>合作洽詢</Text.Bold>
                <Box as={Media} lessThan="lg">
                  <Text mt={'0.75rem'} color="custom.textGray" fontSize={'0.875em'}>花三分鐘填寫表單，就能讓合作更有效率！完成後即有專人在兩個工作天內回覆</Text>
                </Box>
                <Flex as={Media} greaterThanOrEqual="lg" justify={'center'}>
                  <Text mt="1rem" color="custom.textGray" fontSize="1.875em">花三分鐘填寫表單，就能讓合作更有效率！<br />完成後即有專人在兩個工作天內回覆</Text>
                </Flex>
                <Box pt={responsive('2.125em', '6em')} width={responsive('10.625rem', '25%')}><Image src={need} /></Box>
                {needs.map((d, i) => (
                  <Box mt={i ? '2.375em' : '1.375em'} key={d.name}>
                    <Flex alignItems={'center'} fontWeight={'bold'} fontSize={responsive('1em', '2.5em')}>
                      <Text.Inline fontSize={responsive('0.5rem', '0.8em')}>●</Text.Inline>
                      <Text mx="0.25rem">{d.title}</Text>
                      <Text.Inline color="custom.lightGray">(*可複選)</Text.Inline>
                    </Flex>
                    <Flex mx={responsive(0, '-0.625em')} justifyContent={responsive('space-between', 'flex-start')} flexWrap={'wrap'}>
                      {d.buttons.map((button, k) => isObject(button) ? (
                        <Box width="100%" mx={responsive(0, '0.625em')} key={k}>
                          <Text.Bold fontSize={responsive('0.875em', '1.875em')} mt="1.25rem">{button.subtitle}</Text.Bold>
                          <Flex justifyContent="space-between" flexWrap={'wrap'}>
                            <Flex as={Media} width="100%" justifyContent="space-between" flexWrap={'wrap'} greaterThanOrEqual='lg'>
                              {button.options.map((opt, key) => (
                                <CustomCheckBox
                                  name={`${button.subName}.${key}`}
                                  onChange={handleChange}
                                  value={opt}
                                  width={d.width}
                                  key={`${button.subName}.${key}`}
                                  _hover={{
                                    bg: 'black',
                                    color: 'white'
                                  }}
                                >
                                  {opt}
                                </CustomCheckBox>
                              ))}
                            </Flex>
                            <Media lessThan='lg'>
                              {button.options.map((opt, key) => (
                                <CustomCheckBox
                                  name={`${button.subName}.${key}`}
                                  onChange={handleChange}
                                  value={opt}
                                  width={d.width}
                                  key={`${button.subName}.${key}`}
                                >
                                  {opt}
                                </CustomCheckBox>
                              ))}
                            </Media>
                          </Flex>
                        </Box>
                      ) : (
                        <Box width={d.width} key={k}>
                          <Box as={Media} greaterThanOrEqual="lg" px='0.625em'>
                            <CustomCheckBox
                              name={`${d.name}.${k}`}
                              onChange={handleChange}
                              value={button}
                              _hover={{
                                bg: 'black',
                                color: 'white'
                              }}
                            >
                              {button}
                            </CustomCheckBox>
                          </Box>
                          <Box as={Media} lessThan="lg">
                            <CustomCheckBox
                              name={`${d.name}.${k}`}
                              onChange={handleChange}
                              value={button}
                            >
                              {button}
                            </CustomCheckBox>
                          </Box>
                        </Box>
                      ))}
                    </Flex>
                  </Box>
                ))}
              </Box>
              <Box as={Media} flex={1} lessThan='lg'>
                <BackgroundImage src={contact} ratio={88.61 / 615.84} />
              </Box>
              <Box as={Media} width="63.5%" mx="auto" greaterThanOrEqual='lg'>
                <BackgroundImage src={contact_desk} ratio={749.98 / 111.19} />
              </Box>
            </Flex>
            <Box mx={responsive('2.125em', '3em')}>
              <Box pb={responsive('2.625em', '10.875em')} pt={responsive('2.375em', '6.25em')} borderTop="1px solid" borderColor="custom.borderGray">
                <Box width={responsive('10.625rem', '25%')}><Image src={need} /></Box>
                <Box pt="1.625em">
                  {scales.map((d, i) => (
                    <Box mt={i && '2.5em'} key={d.name}>
                      <Flex alignItems={'center'} fontWeight={'bold'} fontSize={responsive('1em', '2.5em')}>
                        <Text.Inline fontSize={responsive('0.5rem', '0.8em')}>●</Text.Inline>
                        <Text mx="0.25rem" >{d.title}</Text>
                      </Flex>
                      <Slider mt={responsive('3.25em', '7.75em')} handleChange={handleChange} name={d.name} marks={d.marks} />
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box pt={responsive('2.375em', '7.875em')} pb={responsive('2.25em', '3em')} borderTop="1px solid" borderColor="custom.borderGray">
                <Box width={responsive('13.375rem', '30%')}><Image src={normal_info} /></Box>
                <Flex flexDirection={responsive('column', 'row')} flexWrap={'wrap'} pt="1.25em">
                  {info.map(({ Comp = Input, require, ...d }, i) => (
                    <Box width={responsive('100%', Comp === Input ? '50%' : '100%')} mt={'1.5em'} key={d.name}>
                      <Flex alignItems={'center'} fontWeight={'bold'} ml={responsive(0, i % 2 && Comp === Input && '1.625rem')} fontSize={responsive('1em', '2.5em')}>
                        <Text.Inline fontSize={responsive('0.5rem', '0.8em')}>●</Text.Inline>
                        <Text mx="0.25rem">{d.label}</Text>
                        {require && <sup style={{ color: "red" }}>*</sup>}
                      </Flex>
                      {d.options ? (
                        <>
                          <Radio handleChange={handleChange} name={d.name} options={d.options} />
                          {Object.keys(errors).includes(d.name) && validate && <Text fontSize={responsive('0.875em', '1.5em')} mt="0.5em" color="red">{errors[d.name]}</Text>}
                        </>
                      ): (
                        <Box mr={responsive(0, i % 2 == 0 && '1.625rem')} ml={responsive(0, i % 2 && '1.625rem')} mt="0.75em">
                          <Comp
                            placeholder={d.placeholder}
                            onChange={handleChange}
                            name={d.name}
                          />
                          {Object.keys(errors).includes(d.name) && validate && <Text fontSize={responsive('0.875em', '1.5em')} mt="0.5em" color="red">{errors[d.name]}</Text>}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Box>
            <Box textAlign={'center'}>
              <Button.Outline
                isLoading={isSubmitting}
                onClick={() => setSchema(true)}
                type="submit"
                fontSize={responsive('1.25em', '2.5em')}
                py={responsive('0.5em', '0.42em')}
                px={responsive('1rem', '0.875em')}
                leftIcon={<IoMdSend />}
                trackerName="collab_submit"
              >
                送出表單
              </Button.Outline>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
};





export default ContactPage;
